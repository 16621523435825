@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

body {
    margin: 0;
    padding: 0;
    background-color: #faf3f2; /* Changed to softer off-white */
    font-family: 'Lato', sans-serif;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}
html, body {
    overflow-x: hidden;
    touch-action: pan-y; /* Restrict swiping to vertical only */
    scroll-behavior: smooth; /* Smooth scrolling */
}
.container, .App {
    max-width: 100vw; /* Restrict maximum width */
    overflow: hidden; /* Ensure content doesn't overflow */
}


* {
    box-sizing: border-box;
    text-align: center;
}

span {
    color: white; /* Updated primary color */
    background-color: #264653;
    border-radius: 3px;
    transition: 0.3s ease;
    
}
    span1 {
        color: white;
        background-color: #264653;
        text-transform: uppercase;
        font-weight: bold; /* Make text bold */
        font-size: 1.75rem; /* Increase font size */
        padding: 10px 20px; /* Add padding for more prominence */
        letter-spacing: 2px; /* Add spacing between letters for a bold effect */
        border: 3px solid #f4a261; /* Add a thick orange border for emphasis */
        border-radius: 5px; /* Slightly round the corners */
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Add a stronger shadow */
        transition: all 0.3s ease;
        display: inline-block;
    }
    
    span1:hover {
        background-color: #f4a261; /* Change background color on hover */
        color: #264653; /* Invert text color on hover */
        transform: scale(1.05); /* Slightly enlarge on hover */
        box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
    }
    

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: #264653; /* Updated primary color */
    border-radius: 3px;
    transition: 0.3s ease;
}

a1 {
    width: 160px;
    height: 50px;
    margin-left: 44.4%;
    margin-right: -85%;
    background-color: #264653; /* Updated primary button color */
    color: #ffffff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    transition: 0.3s ease;
    border: 2px solid #f4a261;
}

button, input {
    outline: none;
    border: none;
}

.logo img {
    width: 150px;
    background-image: white;
}

#main {
    width: 100%;
    height: 768px;
    background-image: url('./images/banner.png');
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 0;
}

#main::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 100%);
    z-index: -1;
}

.whatsapp-btn img {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    z-index: 1000;
}

nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0; /* Added this to ensure it's flush with the left side */
    width: 100%;
    z-index: 1;
    background-color: transparent;
    transition: 0.6s ease-in-out;
    padding: 0; /* Removed any padding that was pushing it down */
    margin: 0;  /* Removed margin */
}
body, html {
    touch-action: manipulation;
    overscroll-behavior: none; /* Prevent panning beyond the page */
}

nav.active {
    background-color: #264653; /* Updated navbar active state */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

nav ul {
    display: flex;
}

nav ul li a {
    padding: 0 22px;
    line-height: 40px;
    text-transform: uppercase;
    font-weight: 500;
    color: #ffffff;
    background-color: #264653; /* Updated */
    border-radius: 3px;
    transition: 0.3s ease;
}

nav ul li a:hover, .active {
    background-color: #f4a261; /* Updated to coral */
    color: #ffffff;
}

.name {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -40%);
    text-align: center;
    width: 900px;
}

.name h1, .name h2 {
    color: #ffffff;
    margin: 0;
}

.name h1 {
    font-size: 2.5rem;
}

.name h2 {
    font-size: 3rem;
}

.name .details {
    color: #faf3f2; /* Softer off-white background for contrast */
    font-size: 1.4rem;
}

.header-btns {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.header-btn {
    width: 160px;
    height: 50px;
    background-color: #264653; /* Updated */
    color: #ffffff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    transition: 0.3s ease;
    border: 2px solid #f4a261;
}

.header-btn:hover {
    background-color: transparent;
    color: #264653; /* Updated */
    border: 2px solid #264653; /* Updated */
}

#features {
    width: 100%;
    padding: 40px 0;
    background-color: #faf3f2; /* Softer off-white */
    text-align: center;
}

#features h1 {
    font-size: 2.5rem;
    color: #264653; /* Updated */
}

.a-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.a-box {
    background-color: #ffffff;
    border: 1px solid #ccc;
    width: 250px;
    height: 400px;
    margin: 20px;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.a-b-img img {
    width: 100%;
    height: 60%;
    object-fit: cover;
}

.a-box:hover {
    border-color: #264653; /* Updated */
    box-shadow: 0 4px 12px rgba(38, 70, 83, 0.3); /* Updated */
}

.a-b-text h2 {
    font-size: 1.5rem;
    color: #264653; /* Updated */
}

.a-b-text p {
    font-size: 1.1rem;
    color: #333;
}

#about {
    display: flex;
    padding: 50px;
    background-color: #faf3f2; /* Softer off-white */
}

.about-text {
    width: 50%;
}

.about-text h1 {
    color: #264653; /* Updated */
    font-size: 3rem;
}

.about-text p {
    font-size: 1.2rem;
    color: #333;
}

.about-text button {
    margin-top: 20px;
    background-color: #264653; /* Updated */
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    transition: 0.3s;
    border: 1.5px solid #f4a261;
}

.about-text button:hover {
    background-color: transparent;
    color: #264653; /* Updated */
    border: 2px solid #264653; /* Updated */
}

.about-image img {
    width: 50%;
    height: 80%;
}

#contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    background-color: #ffffff;
}

#contact h1, #contact h2 {
    font-size: 2.5rem;
    color: #264653; /* Updated */
}

#contact form {
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

#contact form input, #contact form textarea {
    width: 100%;
    padding: 15px;
    background-color: #faf3f2; /* Softer off-white */
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #333;
    border: 1px solid #f4a261;
}

#contact form input[type="submit"] {
    background-color: #264653; /* Updated */
    color: #ffffff;
    cursor: pointer;
}


/* Responsive Menu and Text */

@media(max-width:1100px){.menu-icon{
    display:block;}
        nav{
            justify-content: space-between;
            height: 65px;
            padding: 0px 30px;
        }
        .pr-heading{
            position: relative;
            top: 200px;
        }
        .logo img{
            width:70px;
        }
        .header-btns{
            margin: 0;
        }
        .pr-btns{
            margin: 0;
        }
        .menu{
            display:none;
            position:absolute;
            top:65px;
            left:0px;
            width:175%;
            padding:0px;
            margin:0px;
        }
        .menu li{
            width:100%;
        }
        nav .menu li a{
            width:100%;
            height:40px;
            justify-content: center;
            font-size: 0.8rem;
            align-items: center;
            margin:0px;
            padding: 5px;
            border:1px solid rgba(38,38,38,0.03);
        }
        nav .menu-icon{
            cursor:pointer;
            float:right;
            padding:28px 20px;
            position:relative;
            -ms-user-select: none;
            user-select: none;
        }
        nav .menu-icon .nav-icon{
            background-color:#ffffff;
            display:block;
            height:2px;
            position:relative;
            transition: background 0.2s ease-out;
            width:24px;
        }
        nav .menu-icon .nav-icon:before,
        nav .menu-icon .nav-icon:after{
            background:rgb(255, 255, 255);
            content:'';
            display:block;
            height:100%;
            position:absolute;
            transition:all ease-out 0.2s;
            width:100%;
        }
        nav .menu-icon .nav-icon:before{
            top:6px;
        }
        nav .menu-icon .nav-icon:after{
            top:-6px;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon{
            background:transparent;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon:before{
            transform: rotate(-45deg);
            top:0;
        }
        nav .menu-btn:checked ~ .menu-icon .nav-icon:after{
            transform: rotate(45deg);
            top:0;
        }
        nav .menu-btn{
            display:none;
        }
        nav .menu-btn:checked ~ .menu{
            display:block;
        }}
    
    @media(max-width:400px){
        .a-box{
            width: 100%;
            height:400px;
        }
        

    nav ul li {
        padding: 20px 0;
    }

    nav ul li a {
        font-size: 1.5rem;
    }

    /* Responsive Text */
    .name h1 {
        font-size: 2rem;
    }

    .name h2 {
        font-size: 1.5rem;
    }

    .name .details {
        font-size: 0.5rem;
    }

    .header-btns {
        flex-direction: column;
        gap: 10px;
    }
    p {
        margin-block-start: 0.6em; /* Adjust top margin for mobile */
        margin-block-end: 0.6em;   /* Adjust bottom margin for mobile */
        font-size: 1%;          /* Even smaller font size for mobile */
    }
}
/* Responsive Styles */
@media (max-width: 768px) {
    #about {
        flex-direction: column; /* Stack image and text vertically */
        padding: 20px; /* Reduce padding for smaller screens */
    }

    .about-image {
        display: none; /* Hide the image on small screens */
    }

    .about-text {
        width: 100%; /* Full width */
        padding: 0; /* Remove padding */
        text-align: center; /* Center text */
    }

    .about-text h1 {
        font-size: 2rem; /* Adjust heading size */
    }

    .about-text p {
        font-size: 1rem; /* Adjust paragraph font size */
    }

    .about-text button {
        font-size: 1rem; /* Adjust button text size */
        padding: 8px 15px; /* Adjust button padding */
    }
    span {
        font-size: 2.2rem; /* Smaller font size for smaller screens */
    }
    a1 {
        width: 120px; /* Adjust width for tablets */
        margin-left: 33%; /* Adjust centering */
        font-size: 1rem; /* Smaller font size for tablets */
    }
    p {
        margin-block-start: 0.8em; /* Adjust top margin for tablets */
        margin-block-end: 0.8em; /* Adjust bottom margin for tablets */
        font-size: 0.9rem; /* Smaller font size for tablets */
    }
    .whatsapp-btn img {
        width: 40px; /* Adjust WhatsApp icon size */
        height: 40px;
    }
    .pr-heading {
        position: relative;
        top: -5px;
    }
    .name h1 {
        font-size: 1.5rem; /* Slightly smaller font size for tablets */
        line-height: 1.3; /* Adjust line height for better spacing */
    }
    p {
        margin-block-start: 0.8em; /* Adjust top margin for tablets */
        margin-block-end: 0.8em;   /* Adjust bottom margin for tablets */
        font-size: -100%;          /* Slightly smaller font size for tablets */
    }
    .name .details {
        color: #f1faee;
        font-size: 0.7rem;
    }
}
@media (max-width: 480px) {
    .name h1 {
        font-size: 1.3rem; /* Even smaller font size for mobile */
        line-height: 2; /* Further adjust line height for mobile */
    }
    p {
        margin-block-start: 0.6em; /* Adjust top margin for mobile */
        margin-block-end: 0.6em;   /* Adjust bottom margin for mobile */
    }
    #contact form {
        width: 90%; /* Further adjust width for very small screens */
    }

    /* Adjust dimensions for service icons or other elements */
    .a-box {
        width: 100%;
        height: auto; /* Adjust height as necessary */
    }

}